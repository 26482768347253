<template>
  <v-card class="card-shadow border" color="white">
    <v-row align="end" class="pa-8" style="gap: 30px">
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details />
      <v-btn color="primary" @click.prevent="currentPage = 1;Accounts(search)">Search</v-btn>
    </v-row>
    <v-data-table :headers="headers" :items="accountData" hide-default-footer :loading="loading"
      :loading-text="message" :items-per-page="currentRows">
      <template v-slot:item.action="{ item }">
        <!-- <v-btn @click="handleRedirect(item.id)" class="ma-2" outlined color="indigo">
            {{ item.action }}
          </v-btn> -->
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small outlined color="primary" dark v-bind="attrs" v-on="on">
              {{ item.action }} <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click='handleContact(item.id)'><v-list-title>Contacts</v-list-title></v-list-item>
            <v-list-item link @click='handleCard(item.id)'><v-list-title>Cards</v-list-title></v-list-item>
            <v-list-item link @click='handleTransaction(item.id)'><v-list-title>Transaction</v-list-title></v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-row align="center" justify="end">
      <v-col cols="auto">
        <v-select style="width: 60px;" :items="rowsPerPage" @change="handleChangeRows" v-model="currentRows" />
      </v-col>
      <v-col cols="auto" style="min-width: 400px;">
        <v-pagination @input="pageChange" color="black" v-model="currentPage" :length="noOfPages"
          :total-visible="7" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      noOfPages: 1,

      rowsPerPage: [10, 15, 30, 50],
      currentRows: 10,


      accountData: [],
      data: '',
      message: 'Loading... Please wait',
      loading: true,
      search: '',
      headers: [
        { text: 'Label', value: 'label' },
        { text: 'Account Type', value: 'account_type' },
        { text: 'Customer Name', value: 'full_name'},
        { text: 'Account Number', value: 'account_number' },
        { text: 'Account Nickname', value: 'account_nickname' },
        { text: 'Action', value: 'action' },
      ],

      abortController: new AbortController()
    };
  },
  props: {
    currentServer: Object,
  },
  methods: {
    handleTransaction(id) {
      const serverType = localStorage.getItem('serverType')
      if (serverType == 'staging') {
        this.$router.push(`/skyfi-dashboard/staging/transactions/${id}?for=account`);
      } else if (serverType == 'livestaging') {
        this.$router.push(`/skyfi-dashboard/live-staging/transactions/${id}?for=account`);
      } else if (serverType == 'local') {
        this.$router.push(`/skyfi-dashboard/local/transactions/${id}?for=account`);
      } else {
        this.$router.push(`/skyfi-dashboard/transactions/${id}?for=account`);
      }
    },
    handleContact(id) {
      this.$router.push(`/skyfi-dashboard/contacts/${id}`);
    },
    handleCard(id) {
      this.$router.push(`/skyfi-dashboard/cards/${id}`);
    },
    async Accounts(searchString) {
      try {
        this.abortController.abort(); // Cancel previous requests
        this.abortController = new AbortController(); // Create new AbortController
        const signal = this.abortController.signal;

        this.loading = true
        this.message = 'Loading... Please wait'
        const v_page = '?page=' + this.currentPage;
        const v_per_page = '&per_page=' + this.currentRows;
        const businessId = this.$route.params.id ? '&business_id=' + this.$route.params.id : '';
        const SearchString = searchString ? '&search_string=' + searchString : '';

        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
        const baseurl = this.currentServer?.baseUrl
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const response = await fetch(
          `${baseurl}admin/accounts${v_page + v_per_page + businessId + SearchString}`,
          {
            'Content-Type': 'application/json',
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
            signal
          }
        );
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.json();
        const businessesAccount = data?.data;
        this.data = businessesAccount
        const AccountArray = businessesAccount?.map((item) => {
          return {
            id: item.id,
            label: item?.attributes?.label,
            account_type: item?.attributes?.account_type,
            account_number: item?.attributes?.finxact_party_account_num,
            account_nickname: item?.attributes?.accountNickName,
            full_name: item?.attributes?.full_name,
            action: 'options'
          }
        })
        this.accountData = AccountArray;
        this.noOfPages = Math.ceil(parseInt(data?.total_business_accounts) / this.currentRows);
        this.loading = false
        this.message = 'something went wrong'
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.message.toUpperCase() === 'Network response was not ok'.toUpperCase()) {
          this.$router.push('/admin-login');
        }
        this.loading = false
        this.message = 'something went wrong'
      }
    },
    pageChange() {
      this.Accounts(this.search);
    },
    handleChangeRows() {
      this.currentPage = 1
      this.Accounts(this.search);
    },
  },
  mounted() {
    this.Accounts();
  },
  watch: {
    $route() {
      this.message = 'Loading... Please wait'
      this.loading = true
      this.search = ''
      this.Accounts()
    },
  },
};
</script>
  